import api from '@/base/utils/request'

export const getFormConfig = (id) => {
  return api({
    url: '/admin/venue/approvalTmpl/getFormConfigs',
    method: 'post',
    data: {id}
  })
}

export const saveFormConfig = (data) => {
  return api({
    url: '/admin/venue/approvalTmpl/saveFormConfigs',
    method: 'post',
    data
  })
}
