<template>
  <div class="page-middle">
    <!-- 自定义页面 -->
    <div class="workspace-middle-box" @click="updateSelected('page')">
      <div class="workspace-middle-page">
        <!-- 头部背景层 -->
        <div :class="['drag-item no-hover']" :style="{height: (64) + 'px', position: 'absolute'}">
          <div class="header-fixed" :style="{height: '64px', backgroundColor: 'white'}">
            <!-- 返回按钮 -->
            <img :class="['default-back-icon']" src="../../../assets/images/Back1@2x.png" />
            <!-- 状态栏 -->
            <img class="status-bar" src="../../../assets/images/phone-statusbar1@2x.png" />
          </div>
        </div>
        <div class="workspace-middle-page-content">
          <!-- 默认头部导航 -->
          <div :class="['drag-item no-hover']">
            <st-page-set :config="page.props"></st-page-set>
          </div>

          <!-- 其他组件 -->
          <draggable v-model="myList" :class="['draggable-box']" draggable=".drag-item" animation="400"
            ghostClass="ghost" :options="listOptions" :move="onMove" filter=".no-drag" @sort="handleSort"
            @add="handleAdd">
            <div class="drag-item" ref="formItem" :id="'formItem' + i"
              :data-last="li.props.normal && li.props.normal.marginBottom ? li.props.normal.marginBottom > 0 : false"
              :class="[value === i ? 'active':'', ['st-custom-header', 'st-custom-nav'].includes(li.component) ? 'no-drag' : '', li.title && checkSameTitle(li.props.normal.label || li.props.title,myList,i)?'error':'']"
              @click.stop="updateSelected(i)" v-for="(li, i) in myList" :key="li.id">
              <component v-if="li.is_show !== 0" :is="li.component"
                :config="li.props" :type="li.type" :field="li.field">
              </component>
              <div class="drag-item-tool">
                <div class="tools" v-if="!defaultFields.includes(li.field)">
                  <!-- 添加.stop修饰符阻止冒泡 -->
                  <!-- 删除 -->
                  <img @click.stop="deletePageItem(i)" src="../../../assets/images/del_gray.png" />
                </div>
                <div class="arrow-label" v-if="li.title" :style="{
                  display: defaultFields.includes(li.field)?'block': ''
                }">{{li.title}}</div>
              </div>
              <div class="drag-item-tool-left">
                <div class="disable-label"
                  v-if="li.title && checkSameTitle(li.props.normal.label || li.props.title,myList,i)" :style="{
                    right: (168 + ((li.props.normal.label || li.props.title).length * 12)) + 'px'
                }">不能填写相同的标题：{{li.props.normal.label || li.props.title}}</div>
              </div>
            </div>
            <!-- 为了当页面只有一个禁止停靠元素时，使元素能够停靠在其下方 -->
            <div class="drag-item no-hover hide" style="height: 1px; background-color: transparent; "></div>
          </draggable>
          <!-- 底部按钮 -->
          <div class="drag-item no-hover" :class="[value === 'bottomBtn' ? 'active':'']">
            <st-bottom-button :config="bottomBtn.props"></st-bottom-button>
          </div>
        </div>
      </div>
      <!-- 右下角撤销、重做 -->
<!--      <div class="workspace-middle-undo-manage">-->
<!--        <img @click.stop="undo" :disabled="!undoManager.canUndo" src="../../../assets/images/undo.png" />-->
<!--        <img @click.stop="redo" :disabled="!undoManager.canRedo" src="../../../assets/images/redo.png" />-->
<!--        &lt;!&ndash; <img @click="restore" :disabled="!canRedo" /> &ndash;&gt;-->
<!--      </div>-->
      <!-- 右上角按钮组 -->
      <div class="workspace-middle-buttons">
<!--        <el-button :type="value === 'page' ? 'primary' : ''" @click.stop="updateSelected('page')">-->
<!--          <span class="middle-button-content">-->
<!--            <img v-if="value === 'page'" src="../../../assets/images/page-setting-white.png" />-->
<!--            <img v-else src="../../../assets/images/page-setting.png" />-->
<!--            页面设置</span>-->
<!--        </el-button>-->
        <!--      <el-button v-if="myList.length" :type="value === 'list' ? 'primary' : ''" @click.stop="updateSelected('list')">-->
        <!--        <span class="middle-button-content"><img src="../../../assets/images/settings.png" />组件列表</span>-->
        <!--      </el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";

import UndoManager from "../../../../../base/utils/undo-manager";
var undoManager = UndoManager;

import StPageSet from "../Preview/StPageSet";
import StInput from "../Preview/StInput";
// import StSelect from "../Preview/StSelect";
import StSelect from '../../../../organization-admin/components/OrgFrame/Preview/StSelect'
import StImage from '../../../../organization-admin/components/OrgFrame/Preview/StImage'
import StBottomButton from "../Preview/StBottomButton.vue";

export default {
  name: "PageMiddle",
  components: {
    StImage,
    StSelect,
    StInput,
    StPageSet,
    draggable,
    StBottomButton,
  },
  props: {
    // main.selected (v-model)
    value: {
      type: [String, Number],
    },
    // 接口返回的原始数据
    // pageDetail: Object,
    // 自定义页面相关
    page: Object,
    bottomBtn: Object,
    list: Array,
  },
  data() {
    return {
      undoManager: {
        // 撤销重做
        canUndo: false,
        canRedo: false,
      },
      listOptions: {
        group: {
          name: "drag",
        },
        dragClass: "chosen",
      },
      defaultFields: [],
      myList: [],
    };
  },
  computed: {
    ...mapState("approvalTemplate", ["clone", "library"]),
  },
  watch: {
    list(newValue) {
      this.myList = newValue;
    },
  },
  created() {
    this.myList = this.list;
    this.defaultFields = [
      // "avatar",
      // "nickname",
      // "gender",
      // "phone",
    ]
  },
  methods: {
    /* 表单标题查重方法 */
    checkSameTitle(title, list, index) {
      let flag = false;
      list.forEach((el, i) => {
        if (
          (el.props.normal.label || el.props.title) === title &&
          index !== i
        ) {
          flag = true;
        }
      });
      return flag;
    },
    /* 刷新撤销/重做状态 */
    refreshUndoStatus() {
      this.undoManager.canUndo = undoManager.hasUndo();
      this.undoManager.canRedo = undoManager.hasRedo();
    },
    /* 注意两个拖拽容器都要配置才能禁止停靠 */
    onMove(e) {
      let flag = true;
      /* 不允许拖拽、停靠（需配合draggable的filter属性 */
      if (e.relatedContext.element?.component === "st-custom-header") {
        flag = false;
      }
      return flag;
    },
    /**
     * 监听"页面"拖拽排序操作
     */
    handleSort(ev) {
      if (this.isIgnore) {
        this.isIgnore = false;
      } else {
        let realIndex =
          ev.newIndex === this.myList.length ? ev.newIndex - 1 : ev.newIndex;
        let target = this.myList[realIndex];
        {
          undoManager.add({
            undo: () => {
              this.myList.splice(realIndex, 1);
              this.myList.splice(ev.oldIndex, 0, target);
            },
            redo: () => {
              this.myList.splice(ev.oldIndex, 1);
              this.myList.splice(realIndex, 0, target);
            },
          });
          this.updateSelected(realIndex);
        }
        this.refreshUndoStatus();
        this.$emit("update-list", this.myList);
      }
    },
    /**
     * 从左侧列表添加了一个组件到"页面"
     */
    handleAdd(ev) {
      console.log('add', ev.newIndex, this.clone)
      // 添加一个新的子项目时，也会触发sort事件，所以使用此字段忽略后续操作
      this.isIgnore = true;
      /**
       * 为了实现列表中仅有一个禁止拖拽、停靠的元素时仍能支持停靠在其下方，
       * 固定了一个"假的"列表元素，
       * 导致此事件返回的下标受影响
       * */
      let realIndex =
        ev.newIndex === this.myList.length ? ev.newIndex - 1 : ev.newIndex;
      if (this.clone.data.extra.count >= this.clone.data.extra.maxCount) {
        /* 超过上限，不能添加 */
        this.myList.splice(realIndex, 1);
      } else {
        {
          // 放置普通组件
          let target = this.myList[realIndex];
          {
            /* 普通放置即可 */
            undoManager.add({
              undo: () => {
                this.myList.splice(realIndex, 1);
              },
              redo: () => {
                this.myList.splice(realIndex, 0, target);
              },
            });
            this.updateSelected(realIndex);
            this.updateCount(1);
          }
        }
        this.refreshUndoStatus();
        this.$emit("update-list", this.myList);
      }
    },
    /* 删除 */
    deletePageItem(i) {
      if (typeof this.value === "number" && this.value !== -1) {
        /* 删除后要修改selected */
        if (i > this.value) {
          /* 不用管 */
        } else if (i === this.value) {
          /* 如果后面还有元素，选中那个元素，否则-1 */
          if (!this.myList[i + 1]) {
            this.updateSelected("page");
          }
        } else if (i < this.value) {
          this.updateSelected(this.value - 1);
        }
      }
      let target = this.myList.splice(i, 1)[0];
      undoManager.add({
        undo: () => {
          this.myList.splice(i, 0, target);
        },
        redo: () => {
          this.myList.splice(i, 1);
        },
      });
      this.refreshUndoStatus();
      /* 组件计数减1 */
      this.updateCount(0, target);
      this.$emit("update-list", this.myList);
    },
    /* 修改组件使用次数 */
    updateCount(plus, target) {
      /* 组件使用次数+1 */
      let newLibrary = JSON.parse(JSON.stringify(this.library));
      if (plus) {
        if (!target) {
          /* 增加 */
          newLibrary[this.clone.key].forEach((li) => {
            if (li.field === this.clone.data.field) {
              li.extra && li.extra.count++;
            }
          });
        } else {
          newLibrary["base"].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count++;
            }
          });
          newLibrary["personal"].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count++;
            }
          });
          newLibrary["other"].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count++;
            }
          });
        }
      } else {
        /* 减少 */
        if (target.extra && target.extra.type) {
          newLibrary[target.extra.type].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count--;
            }
          });
        } else {
          /* 编辑页面 */
          newLibrary["base"].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count--;
            }
          });
          newLibrary["personal"].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count--;
            }
          });
          newLibrary["other"].forEach((li) => {
            if (li.field === target.field) {
              li.extra && li.extra.count--;
            }
          });
        }
      }

      this.$store.commit("approvalTemplate/setStateAttr", {
        key: 'library',
        val: newLibrary
      });
    },
    updateSelected(key) {
      this.$emit("input", key);
    },
    undo() {
      this.updateSelected("page");
      if (this.undoManager.canUndo) {
        undoManager.undo();
        this.refreshUndoStatus();
      } else {
        this.$message.info("不能再撤回了");
      }
    },
    redo() {
      this.updateSelected("page");
      if (this.undoManager.canRedo) {
        undoManager.redo();
        this.refreshUndoStatus();
      } else {
        this.$message.info("不能再恢复了");
      }
    },
    // 表单配置校验错误提示及定位
    errFormConfig(index) {
      this.$nextTick(() => {
        let elem = document.getElementById("formItem" + index);
        elem.scrollIntoView({
          behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: "center", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: "nearest", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-middle {
  position: relative;
  height: 100%;
  ::v-deep.el-image__error {
    display: none;
  }
}

.workspace-middle-box {
  padding: 63px 0 80px;
  overflow-y: auto;
  height: 100%;

  .workspace-middle-page {
    box-shadow: 0 4px 8px 0 #d5deeb;
    margin: 0 auto;
    width: 375px;
    min-height: 667px;
    position: relative;
    background-color: #f4f5f6;

    &.no-hover {
      &::before {
        display: none;
      }

      .drag-item {
        &:hover {
          outline: none;
        }

        &.active {
          outline: none;
        }
      }
    }
  }

  .bottomWatermark {
    position: absolute;
    bottom: 100px;
  }

  .workspace-middle-page-content {
    position: relative;
    min-height: 667px;

    &::before {
      content: "iPhone 8 手机高度";
      font-size: 12px;
      line-height: 17px;
      padding: 3px 12px 3px 0;
      color: #3a3a3a;
      position: absolute;
      top: 643px;
      border-bottom: 1px solid #606266;
      transform: translateX(-100%);
    }
  }
}

/* 固定按钮：撤销、重做 */
.workspace-middle-undo-manage {
  position: absolute;
  right: 32px;
  bottom: 10%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 25px;
  z-index: 3;
  height: 42px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #d5deeb;
  border-radius: 21px;
  padding: 0 6px;

  img {
    width: 28px;
    margin: 0 6px;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}

/* 固定按钮 */
.workspace-middle-buttons {
  position: absolute;
  top: 63px;
  right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;

  .el-button {
    margin-left: 0;
    margin-top: 12px;
    font-size: 12px;
    line-height: 12px;
    padding: 8px 10px;
    border: none;
    box-shadow: 0 2px 4px 0 #d5deeb;
  }

  .middle-button-content {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
      width: 16px;
    }
  }
}

/* 使组件放置区域变大 */
.draggable-box {
  padding: 20px 0;
  /* iPhone 8 手机高度 - (状态栏 + 导航栏) - 底部栏 */
  min-height: 667px - 64px - 74px;

  &.custom {
    min-height: 617px;
  }
}

.drag-item-tool {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -42px;
  top: 0;

  .tools {
    display: none;
    /*position: absolute;*/
    /*width: 40px;*/
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 0 2px 2px 0;
    cursor: default;

    img {
      cursor: pointer;
      font-size: 18px;
      margin: 12px 0;
      width: 20px;
    }
  }

  .arrow-label {
    display: block;
    position: absolute;
    right: 28px;
    transform: translateX(100%);
    top: 0;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #3a3a3a;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::before {
      content: "\00a0";
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 16px 7px 0;
      border-color: transparent white transparent transparent;
      position: absolute;
      left: -15%;
    }
  }
}

.drag-item-tool-left {
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  left: -42px;
  top: 0;

  .disable-label {
    display: block;
    position: absolute;
    transform: translateX(100%);
    top: 10px;
    background-color: white;
    box-shadow: 0 2px 4px 0 #d5deeb;
    border-radius: 2px;
    font-size: 12px;
    color: #f56c6c;
    line-height: 12px;
    padding: 8px 15px;
    white-space: nowrap;
    pointer-events: none;

    &::after {
      content: "\00a0";
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-width: 7px 0 7px 16px;
      border-color: transparent white transparent;
      position: absolute;
      right: -8%;
      top: 7px;
    }
  }
}

.header-fixed {
  width: 375px;
  position: absolute;
  z-index: 0;
  top: 0;

  .page-bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    .el-image {
      width: 375px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -187px;
    }
  }

  .default-back-icon {
    position: absolute;
    top: 33px;
    left: 11px;
    width: 20px;
    height: 20px;
  }

  .template-back-icon {
    position: absolute;
    top: 26px;
    left: 5px;
    width: 78px;
    height: 32px;
  }

  .link-dialog1 {
    width: 129px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d3d3d3;
    padding: 0 11px;

    .link-item1 {
      padding: 13px 0;
      display: flex;
      align-items: center;
      .link-image1 {
        width: 20px;
        height: 20px;
        margin-right: 9px;
      }
      .link-name1 {
        font-size: 14px;
        color: #111111;
      }
    }

    .link-item1 + .link-item1 {
      border-top: 1px solid #d3d3d3;
    }
  }

  .link-dialog2 {
    width: 283px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 13px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #d3d3d3;

    .link-item2 {
      text-align: center;
      flex: 0 0 25%;
      margin-bottom: 13px;
      .link-image2 {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
      .link-name2 {
        font-size: 14px;
        line-height: 14px;
        color: #111111;
      }
    }
  }

  .back-icon {
    width: 100%;
    height: 100%;
  }

  .el-image {
    width: 100%;
    height: 100%;
  }
}

.status-bar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20px;
  z-index: 2;
}

.drag-item {
  width: 100%;
  position: relative;
  outline: none;

  &.fixed {
    position: absolute;
    bottom: 0;
    height: 50px;
  }

  &:hover {
    outline: 2px dashed #3479ef;
    outline-offset: -2px;
    cursor: move;

    .tools {
      display: flex;
    }

    .arrow-label {
      display: none;
    }
  }

  &.error {
    outline: 2px solid #f56c6c;
    outline-offset: -2px;
  }

  &.active {
    outline: 2px solid #3479ef;
    outline-offset: -2px;
  }

  &.no-hover {
    outline: none;
    cursor: default;
  }

  .resize {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: s-resize;
  }

  & + .drag-item {
    &.hide {
      margin-top: 0;
    }
  }
}
/* 针对表单分组样式 */
.drag-item {
  & + .drag-item {
    .st-form-item,
    ::v-deep.st-form-item {
      .base-form-item-inner {
        border-top: 1px solid #f1f1f1;
      }
    }
  }
}

.drag-item[data-last="true"] {
  .st-form-item,
  ::v-deep.st-form-item {
    .base-form-item-box {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }

  & + .drag-item {
    .st-form-item,
    ::v-deep.st-form-item {
      .base-form-item-box {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      .base-form-item-inner {
        border-top: none;
      }
    }
  }
}

.draggable-box {
  .drag-item {
    &:first-child {
      .st-form-item,
      ::v-deep.st-form-item {
        .base-form-item-box {
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }

  .drag-item:nth-last-child(2) {
    .st-form-item,
    ::v-deep.st-form-item {
      .base-form-item-box {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
</style>
