const currentcyNormal = {
  // privacy_default_value: function () {
  //   return (val, rule, fApi) => {
  //     rule.display = fApi.form.privacy_fix_user === 1
  //   }
  // }
}
/**
 * 每个组件都会有的"显示约束"配置
 *
 * @type {{showConfig: {dependence: (function(*): Function)}}}
 */
const common = {
  showConfig: {
    dependence: function (vm) {
      return (val, rule, fApi, init) => {
        // rule.props.formConfigs =
        if (init) {
          // console.log('form creator init', vm.myValue, vm.myValue.normal.label)
          rule.props.form = vm.myValue
        }
      }
    },
  },
}
const update = {
  'base-time-picker': {
    normal: {
      dateType: function () {
        return (val, rule, fApi) => {
          fApi.form.dateType =
            fApi.form.dateType === undefined ? 2 : fApi.form.dateType
        }
      },
      ...currentcyNormal,
    },
    ...common,
  },
  'base-area-picker': {
    normal: {
      showType: function () {
        return (val, rule, fApi) => {
          fApi.form.showType =
            fApi.form.showType === undefined ? 1 : fApi.form.showType
        }
      },
      ...currentcyNormal,
    },
    ...common,
  },
  'base-input': {
    normal: currentcyNormal,
    ...common,
  },
  'base-input-number': {
    normal: currentcyNormal,
    ...common,
  },
  'base-multi-select': {
    ...common,
  },
  'base-textarea': {
    normal: currentcyNormal,
    ...common,
  },
  email: {
    normal: currentcyNormal,
    ...common,
  },
  'base-select': {
    normal: currentcyNormal,
    ...common,
  },
  attachment: {
    normal: currentcyNormal,
    ...common,
  },
  'base-file-picker': {
    normal: currentcyNormal,
    ...common,
  },
  phone: {
    normal: currentcyNormal,
    ...common,
  },
  gender: {
    normal: currentcyNormal,
    ...common,
  },
  location: {
    ...common,
  },
  page: {
    // normal: {
    //   titleConfig: function (vm) {
    //     return (val, rule, fApi) => {
    //       const _this = vm
    //       const filterKey = ['fontWeight', 'fontSize', 'fontColor']
    //       fApi.rule.forEach((el) => {
    //         if (filterKey.includes(el.field)) {
    //           el.display = val.isShow === 1
    //         }
    //       })
    //     }
    //   },
    // }
  },
}

export default update
