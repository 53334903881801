<template>
  <div class="st-form-editor">
    <div class="flex-col" v-if="myValue && JSON.stringify(myValue) !== '{}'">
      <div
        class="editor-item"
        v-for="key in Object.keys(myValue)"
        :key="type + key"
        :style="{
          order: keyDict.indexOf(key) > -1 ? keyDict.indexOf(key) : 99,
        }"
      >
        <template v-if="rulesArr && rulesArr[key]">
          <div v-show="rulesArr[key].isShow !== 0">
            <p class="form-title" v-if="rulesArr[key].title">
              <span>{{ rulesArr[key].title }}</span>
            </p>
            <form-create
              :rule="rulesArr[key].rules"
              v-model="fApi"
              :option="options"
              :value.sync="myValue[key]"
            >
            </form-create>
            <div
              v-if="
                !rulesArr[key] ||
                (rulesArr[key].rules && !rulesArr[key].rules.length)
              "
              class="st-form-editor-disable"
              style="height: 250px"
            >
              <div class="disable-tip"></div>
            </div>
          </div>
        </template>
        <div v-else class="st-form-editor-disable">
          <div class="disable-tip">
            <el-image
              class="tip-icon"
              :src="require('../../../assets/images/disable-edit-icon.png')"
            />
            <p class="tip-text">暂无设置</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="st-form-editor-disable">
      <div class="disable-tip">
        <el-image
          class="tip-icon"
          :src="require('../../../assets/images/disable-edit-icon.png')"
        />
        <p class="tip-text">暂无设置</p>
      </div>
    </div>
    <div
      class="jump-btn"
      v-if="rulesArr && rulesArr.pageJump && rulesArr.pageJump.isShow"
    >
      <el-button size="mini" plain @click="handleJumpPage"
        >{{ rulesArr.pageJump.title }}
      </el-button>
    </div>
  </div>
</template>

<script>
import StDragOptionList from '../Form/StDragOptionList'
import update from '../../../js/update'

import SingleMediaWall from '../../../../common/components/SingleMediaWall'
import MediaWall from '../../../../common/components/MediaWall'
import StNumberSlider from '../Form/StNumberSlider'
import StColorPicker from '../Form/StColorPicker'
import StRadioButtonGroup from '../Form/StRadioButtonGroup'
import StTemplateRadio from '../Form/StTemplateRadio'
// import StDragImageSelector from '../Form/StDragImageSelector'
import StIsShowTitle from '../Form/StIsShowTitle'
import StCheckBoxGroup from '../Form/StCheckBoxGroup'
import StUnCheckableBox from '../Form/StUnCheckableBox'
// import StDragIdentity from '../Form/StDragIdentity'
import StCityData from '../Form/StCityData'
import RestrictSelector from '../../../../organization-admin/components/OrgFrame/Form/RestrictSelector'
// import StSpecialInput from '../../../../app-design/components/Form/StSpecialInput'

import formCreate from '@form-create/element-ui'

// formCreate.component(StSpecialInput)
formCreate.component(RestrictSelector)
formCreate.component(MediaWall)
formCreate.component(SingleMediaWall)
formCreate.component(StNumberSlider)
formCreate.component(StColorPicker)
formCreate.component(StRadioButtonGroup)
formCreate.component(StTemplateRadio)
// formCreate.component(StDragImageSelector)
formCreate.component(StIsShowTitle)
formCreate.component(StCheckBoxGroup)
formCreate.component(StUnCheckableBox)
// formCreate.component(StDragIdentity)
formCreate.component(StDragOptionList)
formCreate.component(StCityData)

export default {
  name: 'StFormEditor',
  components: {
    StDragOptionList,
    formCreate: formCreate.$form(),
  },
  model: {
    prop: 'value',
    event: 'updateValue',
  },
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    // example: "st-page-set"
    component: {
      type: String,
    },
    type: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rulesArr: {},
      fApi: {},
      options: {
        // 整体表单样式规则
        form: {
          size: 'small',
          labelWidth: '82px',
          labelPosition: 'left',
        },
        submitBtn: {
          show: false,
        },
        mounted: (fApi) => {
          fApi.rule.forEach((item, i) => {
            if (item.update) {
              const updateKey = item.update
              try {
                if (typeof updateKey !== 'function') {
                  item.update = update[this.field][updateKey][item.field](this)
                }
                // 如果第四个参数为true，表示初始化状态，适合做一些初始操作
                item.update(item.value, item, fApi, true)
              } catch (err) {
                // console.log(err, item.field, this.field) // update 规则未实现
              }
            }
          })
        },
        onSubmit: (formData) => {
          console.log(formData)
        },
      },
      // order数值越小，越靠前
      keyDict: [
        'page_config',
        'templateData',
        'normal',
        'header',
        'searchConfig',
        'share',
        'showConfig',
        'info',
      ],
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('updateValue', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (this.type === 'st-custom-header') {
          // console.log(val.normal)
        }
      },
      deep: true,
    },
  },
  created() {
    // if (this.type === "st-custom-header") {
    //   console.log("StFormEditor created");
    // }
    const isSpecial = ['st-page-set', 'st-bottom-button'].includes(
      this.component
    )
    const rulesArr = require('../../../data/form-create.json')
    const r = rulesArr[this.component]
    if (isSpecial) {
      if (r)
        this.rulesArr = JSON.parse(JSON.stringify(rulesArr[this.component]))
    } else {
      if (r) {
        if (r[this.type]) {
          if (r[this.type][this.field]) {
            this.rulesArr = JSON.parse(JSON.stringify(r[this.type][this.field]))

            // 必须深拷贝
            this.rulesArr.showConfig = JSON.parse(
              JSON.stringify(
                require('@/modules/organization-admin/assets/jsons/common-form-create.json').showConfig
              )
            )
          }
        }
      }
    }
  },
  methods: {
    // 表单底部页面跳转
    handleJumpPage() {
      let routerData = this.$router.resolve({
        name: this.rulesArr.pageJump.routeName,
      })
      window.open(routerData.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
.st-form-editor {
  .editor-item + .editor-item {
    margin-top: 40px;
    /*border-top: 1px solid #eee;*/
  }

  /* 重置el-button按钮样式 */
  .el-button.is-plain {
    color: $st-blue;
    border-color: $st-blue;
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    /*background-color: white;*/
  }

  .el-slider__runway {
    border-radius: 2px;
  }

  .el-slider__runway,
  .el-slider__bar {
    height: 4px;
  }

  .el-slider__runway.show-input {
    width: calc(100% - 146px);
    margin-right: 146px;
  }

  .el-slider__button-wrapper {
    top: -16px;
  }

  .el-slider__button {
    border-color: white;
    box-shadow: 0 0 8px 0 rgba(100, 101, 102, 0.12);
  }

  .el-checkbox__input.is-disabled {
    .el-checkbox__inner {
      background-color: #c0c4cc;
      border-color: #c0c4cc;

      &::after {
        border-color: white;
      }
    }
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 24px;
  }

  .el-form-item--small .el-form-item__label {
    line-height: 30px;
  }

  .el-form-item__content {
    & > .el-cascader,
    & > .el-input,
    & > .el-select {
      width: 235px;
      float: right;
    }

    & > .el-checkbox {
      float: right;
    }
  }

  .el-input--small {
    line-height: 30px;

    .el-input__inner {
      height: 30px;
    }
  }

  .el-radio-button--small .el-radio-button__inner {
    padding: 8px 13px;
    min-width: 48px;
  }

  .el-input-number--small {
    line-height: 28px;
  }

  .el-color-picker--small {
    height: 30px;

    .el-color-picker__trigger {
      border-color: #dcdfe6;
      border-radius: 2px;
      padding: 2px;
    }

    .el-color-picker__color {
      border-color: #dcdfe6;
      border-radius: 0;
    }
  }
}

// 组件配置不可编辑缺省页样式
.st-form-editor-disable {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

  .to-app-design {
    cursor: pointer;
    color: #3479ef;
    margin-left: 4px;
  }

  .disable-tip {
    text-align: center;
    .tip-icon {
      width: 210px;
      height: 100px;
    }
    .tip-text {
      margin-top: 20px;
      font-size: 14px;
      color: #999999;
      line-height: 1.5;
      white-space: pre-wrap;
    }
  }
}
// 表单标题样式
.form-title {
  font-weight: bold;
  font-size: 16px;
  color: #3a3a3a;
  line-height: 16px;
  padding: 20px 0 24px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
}

// 图片提示行内显示样式
.st-inline-img-info {
  color: #c0c4cc;
  position: absolute;
  bottom: 0;
  left: 70px;
  font-size: 12px;
  line-height: 17px;
}

// 单图上传组件提示样式
.st-single-img-info {
  margin-top: 10px;
}

.jump-btn {
  /*position: sticky;*/
  bottom: 0;
  z-index: 2;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .el-button {
    width: 100%;
  }
}
</style>
