<template>
  <div class="drag-list">
    <div class="drag-header">
      <span class="list-title">选项内容</span>
      <span class="form-tip">(至少有一个选项)</span>
    </div>
    <draggable v-if="navList" v-model="navList" class="nav-list" :disabled="unDraggable" animation="500"
      draggable=".nav-item">
      <template v-if="navList.length > 0">
        <div class="nav-item" v-for="(nav, index) in navList" :key="index">
          <div class="item-title">
            <p>选项{{index+1}}</p>
            <div style="display: flex">
              <!-- <el-button class="set-index" type="primary"
                         v-if="nav.is_default !== undefined && nav.is_default === 0" @click="setIndex(index)">设为首页
              </el-button>
              <div class="seted-index" v-if="nav.is_default !== undefined && nav.is_default === 1">
                <img class="seted-icon" src="../../../assets/images/blue-star.png" />
                <p class="seted-text">已设为首页</p>
              </div> -->
              <el-button type="text" style="padding: 0" v-if="showDelectBtn" @click="deleteItem(index)">删除
              </el-button>
            </div>
          </div>
          <div class="item-content">
            <div class="nav-content" @mousemove="unDraggable = true" @mouseout="unDraggable = false">
              <p class="nav-title">选项名称</p>
              <el-input style="width: 100%" v-model="navList[index]" placeholder="请输入选项名称"
                @focus="unDraggable = true" @blur="unDraggable = false">
              </el-input>
            </div>
          </div>
        </div>
      </template>
      <div class="nav-item-add" :style="{marginTop: navList.length>0?'24px':'0'}"
        v-if="navList.length < limit">
        <el-button style="width: 317px" size="mini" type="primary" plain @click="handleAdd">{{addButtonText}}
        </el-button>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "StDragOptionList",
  components: {
    draggable,
  },
  model: {
    prop: "list",
    event: "listChange",
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    addButtonText: {
      type: String,
      default: "添加选项",
    },
    limit: {
      type: Number,
      default: 999,
    },
    input: Boolean
  },
  data() {
    return {
      currentIndex: -1,
      unDraggable: false,
      showDelectBtn: true,
    };
  },
  computed: {
    navList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("listChange", val);
      },
    },
  },
  watch: {
    navList: {
      handler(val) {
        this.showDelectBtn = val.length > 1;
        this.$emit("listChange", val);
      },
      deep: true,
    },
  },
  methods: {
    handleAdd() {
      this.navList.push('');
    },
    // 删除
    deleteItem(i) {
      this.navList.splice(i, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-title {
  display: inline-block;
  font-size: 14px;
  color: #3a3a3a;
  line-height: 14px;
  padding-bottom: 20px;
}
.form-tip {
  margin-left: 14px;
  display: inline-block;
  font-size: 12px;
  color: #c0c4cc;
  padding-top: 8px;
  padding-bottom: 11px;
}
.nav-list {
  background-color: #f6f9ff;
  margin: 0 -19px 0 -24px;
  padding: 16px 12px;
}
.nav-item-add {
  width: 100%;
  text-align: center;
}
.nav-item {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;

  .item-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 22px;
    border-bottom: 1px #eeeeee solid;

    .set-index {
      padding: 4px 12px;
      font-size: 14px;
    }
    .seted-index {
      display: flex;
      align-items: center;
      margin-right: 16px;
      .seted-icon {
        width: 17px;
        height: 16px;
        margin-right: 4px;
      }
      .seted-text {
        font-size: 14px;
        color: #3576ff;
        line-height: 14px;
      }
    }
  }
  .item-content {
    .nav-content {
      margin-top: 20px;
      display: flex;
      position: relative;
      .nav-title {
        line-height: 30px;
        font-size: 14px;
        color: #3a3a3a;
        margin-right: 18px;
        min-width: 56px;
      }
      .nav-btn {
        width: 100%;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .img-info {
        color: #c0c4cc;
        position: absolute;
        bottom: 0;
        left: 150px;
        font-size: 12px;
        line-height: 17px;
      }

      .link-selected {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .link-name {
          width: 175px;
          ::v-deep.el-input__inner {
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: #fff;
            color: #3a3a3a;
            cursor: text;
          }
        }
        ::v-deep.el-button--text {
          padding: 0;
        }
      }
    }
  }
}
.el-input,
.el-select {
  ::v-deep.el-input__inner {
    height: 30px;
  }
  ::v-deep.el-input__icon {
    line-height: 30px;
  }
}

.nav-item + .nav-item {
  margin-top: 24px;
}
</style>
