<template>
  <div class="st-form-item st-input" :style="{
    marginBottom: myConfig.normal.marginBottom + 'px'
  }" :class="[field]">
    <base-form-item :label="myConfig.normal.label" :required="myConfig.normal.required"
      :vertical="myConfig.normal.inputType === 'textarea'">
      <div class="form-item-right" :class="{vertical: myConfig.normal.inputType === 'textarea'}">
<!--        <div v-if="field === 'base-txtarea'" class="textarea-placeholder">{{myConfig.normal.placeholder || defaultPlaceholder}}</div>-->
<!--        <div v-if="field === 'base-txtarea'" class="station" />-->
        <div class="input-placeholder">{{myConfig.normal.placeholder || defaultPlaceholder}}</div>
        <div v-if="type === 'select'" class="el-icon-arrow-right input-icon" />
        <div v-else-if="type === 'input' && field === 'base-input-number'" class="input-append"><span>{{myConfig.normal.unit}}</span></div>
      </div>
    </base-form-item>
  </div>
</template>

<script>
import BaseFormItem from "./BaseFormItem";
export default {
  name: "StInput",
  components: { BaseFormItem },
  props: {
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
    type: {
      type: String,
      default: "input",
    },
    field: {
      type: String,
      default: "base-input",
    },
  },
  data() {
    return {
      // 定义 config 格式和默认数据
      model: {
        normal: {
          marginBottom: 0,
          required: 1,
          label: "标题内容",
          // placeholder: "请输入",
          placeholder: "",
          inputType: "text",
          validate: "",
          min: 1,
          max: 8,
          unit: "",
        },
      },
    };
  },
  computed: {
    myConfig() {
      return this.$mergeModel(this.model, this.config);
    },
    defaultPlaceholder() {
      if (this.type === 'select') return '请选择'
      return '请输入'
    }
  },
  created() {},
};
</script>

<style lang="scss">
  $page-side: 16px;

.st-input {
  /*height: 56px;*/
  .form-item-right {
    padding-left: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.vertical {
      /*padding-left: 8px;*/
      padding: 0 0 0 12px;
    }

    .input-placeholder {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #b7bec8;
      line-height: 56px;
    }

    .input-icon {
      font-size: 18px;
      color: #b7bec8;
      line-height: 56px;
    }

    .textarea-placeholder {
      width: 100%;
      height: 180px;
      border-radius: 6px;
      background-color: rgba(250,250,250,1);
      margin-top: 12px;
    }

    .station {
      height: $page-side;
    }
  }

  .input-append {
    font-size: 14px;
    padding-right: 4px;
  }
}
</style>
